/* App.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

#root {
  background-color: #111;
}

.App {
  height: 100%;
}

canvas {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  background-color: #333;
}